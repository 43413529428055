import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'

export default function WritingPageIndex({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>Ideas and ramblings | {data.site.siteMetadata.title}</title>
        <link rel="canonical" href={data.site.siteMetadata.domain+data.sitePage.path} />
      </Helmet>
      <section className="content-body content-body--project py-5 py-lg-6 animated fadeIn">
        <article className="project-block row-g row-g--project">
          <div className="container-fluid">
            <section className="row py-4 py-lg-0">
              <div className="col-12 px-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2 pt-4 pt-lg-0">
                  {
                    data.allMarkdownRemark.edges.map(post => (
                      <div className="mb-5" key={post.node.id}>
                        <h2><a href={`/writing/${post.node.frontmatter.slug}`}>
                          {post.node.frontmatter.title}
                        </a></h2>
                        <p>{post.node.frontmatter.subTitle}</p>
                      </div>
                    ))
                  }
              </div>
            </section>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            subTitle
          }
        }
      }
    },
    site {
      siteMetadata {
        title
        domain
      }
    },
    sitePage (path: {eq: "/writing/"}) {
      path
    }
  }
`
